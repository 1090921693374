import axios from "axios";
import { getBaseAPI, getConfigBaseAPI } from "../environments/Environments";
import { store } from "../app/store";
import { getAppInsights } from "../utils/AppInsights";

//Switch to 'all' to track in App Insights every single API call. Use for debugging purposes
type TrackingMode = 'errors' | 'all'
const trackingMode: TrackingMode = 'errors';

//Remove any supplied query string
const getBaseFromUrl = (url: string): string => {
  const base = url.split("?")[0];
  return base;
}

//Take any supplied query string and turn into an object
const getQueryParamsFromUrl = (url: string): { [key: string] : string } | undefined => {
  let queryParamsObj: { [key: string] : string } | undefined = undefined;
  const queryString = url.split("?")[1];
  if(queryString) {
    queryParamsObj = {};
    const queryParams = queryString.split("&").map(item => item.split("="));
    for(const param of queryParams) {
      const key = param[0].toLowerCase();
      const val = param[1];
      queryParamsObj[key] = val;
    }
  }
  return queryParamsObj;
}

//It is preferred to supply query params using an object. In case a dev uses a query string we'll
//build an object from it to send to app insights. If for some reason both are sent we'll handle that too.
const consolidateQueryParams = (url: string, queryParams?: any): any => {
  let consolidated: any = queryParams;
  const fromUrl = getQueryParamsFromUrl(url);
  if(fromUrl) {
    consolidated = consolidated ? { ...fromUrl, ...consolidated } : fromUrl;
  }
  return consolidated;
}

const toQueryString = (queryParams?: any): string => {
  let queryStr = '';
  if(queryParams) {
    for(const param of Object.entries(queryParams)) {
      queryStr += (queryStr === '') ? '?' : '&';
      queryStr += `${param[0]}=${param[1]}`;
    }
  }
  return queryStr;
}

const getBaseBookingApi = async (): Promise<string> => {
  const base = await getBaseAPI();
  return `${base}/api/v1/`;
}
const getFullBookingUrl = async (url: string, queryParams?: any): Promise<string> => {
  const base = await getBaseBookingApi();
  return `${base}${url}${toQueryString(queryParams)}`;
}
const getBaseConfigApi = async (): Promise<string> => {
  const base = await getConfigBaseAPI();
  return `${base}/api/v1/`;
}
const getFullConfigUrl = async (url: string, queryParams?: any): Promise<string> => {
  const base = await getBaseConfigApi();
  return `${base}${url}${toQueryString(queryParams)}`;
}
// const getFullGeneralUrl = (baseUrl: string, queryParams?: any): string => {
//   return `${baseUrl}${toQueryString(queryParams)}`;
// }

const track = async (url: string, queryParams: any, payload: any, errorMessage: string | undefined, alwaysTrack?: boolean): Promise<void> => {
  //track only on errors, unless we are configured to track everything or this particular call should always be tracked
  const track: boolean = (trackingMode as TrackingMode === 'all') || (alwaysTrack === true) || (errorMessage !== undefined);
  if(track) {
    const appInsights = await getAppInsights();
    if(appInsights) {
      try {
        const session = store.getState().session;
        const properties: { [key: string] : any } = {
          application: 'WSv6',
          localData: {
            session: session
          }
        };
        if(queryParams !== undefined) {
          properties.queryParams = queryParams;
        }
        if(payload !== undefined) {
          properties.payload = payload;
        }
        if(errorMessage !== undefined) {
          properties.errorMessage = errorMessage;
        }
        // console.log(`sending trace for ${url}`);
        appInsights.trackTrace({
          message: url,
          properties: properties
        });
      } catch (err) {
        console.error('error logging to app insights');
        console.error(err);
      }
    }
  }
}

const apiGet = async (
  fullUrl: string,
  queryParams: any,
  expectJson: boolean,
  alwaysTrack: boolean
): Promise<any> => {
  track(fullUrl, queryParams, undefined, undefined, alwaysTrack);

  try {
    const res = await axios.get(fullUrl, {
      timeout: Number(`${process.env.REACT_APP_REQUEST_TIMEOUT}`),
      headers: {
        'x-helix-apiauth-key': process.env.REACT_APP_HELIX_API_AUTH_KEY || '',
      },
    });

    if (expectJson && (!res.data || typeof res.data !== 'object')) {
      track(fullUrl, queryParams, undefined, 'no data returned');
    }

    return res;
  } catch (err) {
    track(fullUrl, queryParams, undefined, err.toString());
    throw err;
  }
};

const apiPost = async (
  fullUrl: string,
  payload: any,
  queryParams: any,
  expectJson: boolean,
  alwaysTrack: boolean
): Promise<any> => {
  track(fullUrl, queryParams, payload, undefined, alwaysTrack);

  try {
    const res = await axios.post(fullUrl, payload, {
      timeout: Number(`${process.env.REACT_APP_REQUEST_TIMEOUT}`),
      headers: {
        'x-helix-apiauth-key': process.env.REACT_APP_HELIX_API_AUTH_KEY || '',
      },
    });

    if (expectJson && (!res.data || typeof res.data !== 'object')) {
      track(fullUrl, queryParams, payload, 'no data returned');
    }

    return res;
  } catch (err) {
    track(fullUrl, queryParams, payload, err.toString());
    throw err;
  }
};

//expectJson defaults to true in all cases when not supplied
//alwaysTrack defaults to false
export const bookingApiGet = async (url: string, queryParams?: any, expectJson?: boolean, alwaysTrack?: boolean): Promise<any> => {
  const allQueryParams = consolidateQueryParams(url, queryParams);
  return await apiGet(await getFullBookingUrl(getBaseFromUrl(url), allQueryParams), allQueryParams, expectJson !== false, alwaysTrack === true);
}
export const configApiGet = async (url: string, queryParams?: any, expectJson?: boolean, alwaysTrack?: boolean): Promise<any> => {
  const allQueryParams = consolidateQueryParams(url, queryParams);
  return await apiGet(await getFullConfigUrl(getBaseFromUrl(url), allQueryParams), allQueryParams, expectJson !== false, alwaysTrack === true);
}
export const bookingApiPost = async (url: string, payload?: any, queryParams?: any, expectJson?: boolean, alwaysTrack?: boolean): Promise<any> => {
  const allQueryParams = consolidateQueryParams(url, queryParams);
  return await apiPost(await getFullBookingUrl(getBaseFromUrl(url), allQueryParams), payload, allQueryParams, expectJson !== false, alwaysTrack === true);
}
export const configApiPost = async (url: string, payload?: any, queryParams?: any, expectJson?: boolean, alwaysTrack?: boolean): Promise<any> => {
  const allQueryParams = consolidateQueryParams(url, queryParams);
  return await apiPost(await getFullConfigUrl(getBaseFromUrl(url), allQueryParams), payload, allQueryParams, expectJson !== false, alwaysTrack === true);
}
export const apiFetch = async (url: URL | RequestInfo, init?: RequestInit, expectJson?: boolean, alwaysTrack?: boolean): Promise<any> => {
  track(url.toString(), undefined, init?.body, undefined, alwaysTrack);
  try {
    const res = await fetch(url, init);
    const json = await res.json();
    if(expectJson !== false && (json === null || typeof json !== 'object')) {
      track(url.toString(), undefined, init?.body, `no JSON object returned, this was returned: ${json}`);
    }
    return json;
  } catch (err) {
    track(url.toString(), undefined, init?.body, err.toString());
    throw err;
  }
}
//does not automatically parse json, and therefore cannot automatically log app insights in cases of bad json. Use when direct access to response object is needed
export const apiFetchRaw = async (url: URL | RequestInfo, init?: RequestInit, alwaysTrack?: boolean): Promise<any> => {
  track(url.toString(), undefined, init?.body, undefined, alwaysTrack);
  try {
    return await fetch(url, init);
  } catch (err) {
    track(url.toString(), undefined, init?.body, err.toString());
    throw err;
  }
}
